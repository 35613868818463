*:focus {
    outline: none !important;
}

::-webkit-scrollbar {
    width: 0;
}

.kf-navbar {
    background: white;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

}

.kf-navbar .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.kf-navbar .buttons button {
    background: white;
    border: none;
    border-radius: 32px;
    padding: 10px 30px;
    margin-left: 5px;
    font-weight: bold;
}

.kf-navbar .buttons button:hover {
    background: #F5F5F5;
}

.kf-navbar-business {
    background-color: #006B6C !important;
    color: white;
}

.kf-navbar-sign-in {
    background-color: #FF66C4 !important;
    color: white;
}

.kf-navbar-sign-up {
    background-color: #F5F5F5 !important;
}

.w-h-100 {
    width: 100vw;
    height: 100vh;
}

.bottom-spicy {
    position: absolute;
    bottom: 0;
    left: 1%;
    right: 1%;
    width: 98%;
}

.hero-origin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #FF66C4;
}

.hero-origin div {
    color: #6E0F3C;
    font-size: 24px;
    font-weight: 500;
}

.hero-billion {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #cce1e2;
}

.hero-container {
    width: 100%;
    height: 600px;
    background: white;
    border-radius: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-container .origin-title {
    color: #198754;
    font-size: 48px;
    font-weight: bold;
}

.hero-container .origin-text {
    font-weight: bold;
}

.hero-container {
    color: #198754;
}

.kf-card {
    text-align: center;
    background: white;
    border-radius: 32px;
    padding: 30px;
}

.kf-card-title {
    font-size: 32px;
    font-weight: bold;
}

.kf-card-text {
    font-size: 16px;
}

.kf-btn-explore {
    background: #6E0F3C;
    color: white;
    border-radius: 32px;
    font-size: 18px;
    border: none;
    padding: 5px 30px;
}

.flex-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hero-ar {
    background: #cce1e2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.color-green {
    color: #198754;
}

.kf-btn-explore-2 {
    background: #198754;
    color: white;
    border-radius: 32px;
    font-size: 18px;
    border: none;
    padding: 5px 30px;
}

.hero-absolute {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-title {
    font-weight: bold;
    font-size: 100px;
    color: white;
}

.sign-up {
    background: white;
    border-radius: 32px;
    width: 100%;
    height: 600px;
}

.sign-up-title {
    border-radius: 32px 32px 0 0;
    text-align: center;
    height: 125px;
    border-bottom: solid 1px #D4D4D4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #f5f5f5;
}

.sut-title {
    font-size: 32px;
    font-weight: bold;
}

.sut-sub-title {
    font-size: 18px;
    color: gray;
}

.sign-up-body {
    padding: 30px;
    height: 350px;
}

.sign-up-bottom {
    border-radius: 0 0 32px 32px;
    text-align: center;
    height: 125px;
    border-top: solid 1px #D4D4D4;
    background: #f5f5f5;
    text-align: center;
    padding: 30px;
}

.sign-up-bottom a {
    font-weight: bold;
}

.sign-up-button {
    background: #FF66C4;
    color: white;
    width: 100%;
    height: 48px;
    border-radius: 32px;
    border: none;
}

.sign-up-button:disabled {
    background: #F49BED;
}
.file-button:disabled {
    background: #F49BED;
}

.avatar-one {
    width: 100%;
    border-radius: 16px;
    border: solid 1px #D4D4D4;
}

.avatar-img {
    border-radius: 16px 16px 0 0;
    width: 100%;
}

.avatar-bottom {
    border-radius: 0 0 16px 16px;
    background: white;

}

.avatar-bottom-name {
    border-bottom: solid 1px #D4D4D4;
    margin-bottom: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}

.user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: cover;
}

.user-name {
    font-weight: bold;
    font-size: 14px;
}

.login-provider {

}

.login-container {
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cover-image {
    background: #D4D4D4;
    width: 100%;
    height: 350px;
    border-radius: 32px;
}

.cover-image img {
    object-fit: cover;
    width: 100%;
    height: 350px;
    border-radius: 32px;
}

.profile-image {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background: white;
    margin-top: -75px;
}

.profile-image img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 100%;
    border: solid 1px #D4D4D4;
}

.profile-name {
    font-weight: bold;
    font-size: 24px;
}

.profile-username {
    font-weight: bold;
}

.profile-followers {
    font-weight: bold;
}

.profile-menu {
    font-weight: bold;
    font-size: 20px;
}

.profile-menu-active {
    border-bottom: solid 2px #242424;
    height: 35px;
}

.profile-menu-provider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.profile-menu-provider div {
    height: 35px;
    margin-left: 15px;
    margin-right: 15px;
}

.hover-pointer:hover {
    cursor: pointer;
}

.my-collections {
    font-size: 24px;
    font-weight: bold;
}

.new-collection {
    background: #FF66C4;
    border: none;
    border-radius: 4px;
    padding: 10px 30px;
    color: white;
}

.collection-item {
    background: #D4D4D4;
    width: 100%;
    height: 300px;
    position: relative;
    border-radius: 16px;
    margin-bottom: 15px;
}

.collection-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.collection-absolute {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.follow-button {
    border: none;
    border-radius: 4px;
    padding: 5px 30px;
    font-size: 14px;
    background: #FF66C4;
    color: white;
}

.unfollow-button {
    border: none;
    border-radius: 4px;
    padding: 5px 30px;
    font-size: 14px;
}

.collection-cover {
    height: 400px;
    object-fit: cover;
    border-radius: 15px;
}

.mini-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.mini-avatar {
    object-fit: cover;
    border-radius: 100%;
    margin-right: 5px;
}

.mini-title {
    font-size: 14px;
    font-weight: bold;
}

.mini-subtitle {
    margin-top: -7px;
}

.mini-name {
    font-size: 12px;
    color: gray;
}

.collection-title-right {
    border: solid 2px #242424;
    padding: 15px;
    border-radius: 15px;
}

.create-collection {
    background: #D4D4D4;
    padding: 15px;
    border-radius: 4px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.file-button {
    width: 100%;
    margin-top: 15px;
    border: none;
    background: #FF66C4;
    color: white;
    padding: 5px;
    border-radius: 4px;
}

.viewer-modal-3d {
    width: 800px;
    height: 600px;
}